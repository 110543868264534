import React from "react"
import { graphql } from "gatsby"
import Link from 'gatsby-link'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentContainer from "../components/contentContainer"
import {NewsletterTile} from "../components/svgTiles"
import * as indexStyles from "../styles/index.module.scss"
import { getTile } from "../helpers/getTile"

class Index extends React.Component {
   render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const entry1 = data.entry1.edges[0].node
    const entry2 = data.entry2.edges[0].node
    const entry3 = data.entry3.edges[0].node
    const entry4 = data.entry4.edges[0].node
    const entry5 = data.entry5.edges[0].node
    const entry6 = data.entry6.edges[0].node
    const entry7 = data.entry7.edges[0].node
    const partnerEventImage = data.partnerEventImage.edges[0].node
    const seoInfo = data.seoInfo.edges[0].node

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={seoInfo.frontmatter.seoTitle || seoInfo.frontmatter.title} description={seoInfo.frontmatter.seoDescription} />
        <div className={`content`}>
          <ContentContainer>
            <div className="columns is-multiline">
              <div className={`column is-6-desktop is-8-tablet is-12-mobile`}>
                  {getTile(entry1, 1, partnerEventImage, 1, true, true, false, true)}
              </div>
              <div className={`column is-3-desktop is-4-tablet is-12-mobile`}>
                  {getTile(entry2, 2, partnerEventImage, 3, true, true, false, true)}
              </div>
              <div className={`column is-3-desktop is-4-tablet pt-0 pb-0 is-12-mobile ${indexStyles.doubleBoxes}`}>
                <div className="columns is-multiline">
                  <div className={`column is-12 pr-0 is-mobile-12 ${indexStyles.doubleBox}`}>
                    {getTile(entry3, 3, partnerEventImage, 1, false, true, true, true)}
                  </div>
                  <div className={`column is-12 pb-0 pt-0 is-gapless is-mobile-12 ${indexStyles.doubleBox}`}>
                    <Link to="/newsletter">
                      <NewsletterTile isSmall prio={2}/>
                    </Link>
                  </div>
                </div>
              </div>
              <div className={`column is-3-desktop is-4-tablet is-12-mobile`}>
                  {getTile(entry4, 2, partnerEventImage, 3, true, true, false, true)}
              </div>
              <div className={`column is-3-desktop is-4-tablet is-12-mobile`}>
                  {getTile(entry5, 2, partnerEventImage, 3, true, true, false, true)}
              </div>
              <div className={`column  is-3-desktop is-4-tablet is-12-mobile`}>
                  {getTile(entry6, 2, partnerEventImage, 3, true, true, false, true)}
              </div>
              <div className={`column is-3-desktop is-4-tablet is-12-mobile`}>
                  {getTile(entry7, 2, partnerEventImage, 3, true, true, false, true)}
              </div>
            </div>
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    entry1: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(startpage)/Kachel1/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry2: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(startpage)/Kachel2/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }

    entry3: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(startpage)/Kachel3/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry4: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(startpage)/Kachel4/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry5: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(startpage)/Kachel5/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            subtitle
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry6: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(startpage)/Kachel6/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry7: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(startpage)/Kachel7/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    partnerEventImage: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, absolutePath: {regex: "/assets/partnerEventLogo/"}}) {
      edges {
        node {
          id
          childImageSharp {
              fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                originalName
              }
          }
        }
      }
    }
    seoInfo: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/startpage/index.md/"}}) {
      edges {
        node {
          frontmatter {
            seoTitle
            seoDescription
          }
        }
      }
    }
  }
`
